$theme-colors: (
  'primary': #5d54ac,
  'secondary': #5a84e3,
  // "light": #c0c0c0,
  'dark': #151515,
  'danger': #f44336,
);

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&family=Roboto:wght@700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

$primary: #5d54ac;
$secondary: #5a84e3;
// $light: #c0c0c0;
$dark: #151515;
$danger: #f44336;

body {
  font-family: 'Nunito', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.sidebarToggle {
  position: absolute;
  bottom: 0px;
}

.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f9fa;

  .side-menu {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 225px;
  }

  .app-main {
    overflow: hidden;
    height: 100vh;
    width: 100%;
    max-width: calc(100vw - 225px);
    overflow-y: auto;
  }
}

.intro-title-main {
  // font-family: 'Roboto', sans-serif;
}

.side-menu {
  .nav-link {
    padding: 1rem;
    width: 14rem;
    position: relative;
    color: $dark;
    font-weight: bold;

    .link-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
    }

    &::before {
      content: '';
      transition: all 0.3s;
      background-color: rgba($secondary, 0.3);
      height: calc(100% - 10px);
      width: calc(100% - 5px);
      position: absolute;
      top: 5px;
      left: 0px;
      opacity: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:hover {
      color: $dark;
    }
    &:hover::before {
      opacity: 1;
    }

    &.has-children::before {
      display: none;
    }

    &.active::before {
      opacity: 1;
    }

    &.active {
      color: $primary;
    }
    &.active .link-text {
      color: $primary;
    }

    &.has-children {
      background-color: transparent !important;
    }

    .collapse {
      margin-top: 10px;
      background-color: white;
      border-radius: 15px;
      position: relative;
      z-index: 10;
      overflow: hidden;
      padding-left: 10px;

      .collapse-item {
        padding: 0.5rem 1rem;
      }
      .collapse-item::before {
        display: none;
      }
    }
  }
}

.collapse-item {
  border-radius: 15px !important;
  transition: all 0.3s;
}

.collapse-inner {
  background-color: rgba(255, 255, 255, 0.4);
}

.collapse-item:hover {
  background-color: rgba($secondary, 0.5) !important;
}
.collapse-item.active {
  background-color: rgba($secondary, 0.5) !important;
}
.collapse-item.active {
  color: $primary !important;
}

.nav-logo {
  display: flex;
  flex-direction: row;
  justify-content: start;

  img {
    width: 100px;
    height: 60px;
  }
}

.intro-logo {
  width: 150px;
}

.main-title-parent {
  width: 100%;
  max-width: 505px;
  position: relative;

  .main-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    color: $primary;
    word-wrap: break-word;
    font-size: 210px;
    line-height: 0.73;
    text-transform: uppercase;
    text-shadow: -10px 5px 14px rgba($color: #000000, $alpha: 0.14);
    letter-spacing: -17px;
  }

  .monkey {
    position: absolute;
    bottom: -13px;
    right: -29px;
  }
}

// add card sof shadow
// https://neumorphism.io/#e0e0e0
.card {
  border-radius: 15px;
  // background: #e0e0e0;
  box-shadow: -7px 7px 14px #d9d9d9, 7px -7px 14px #ffffff;

  // border: 0px;

  .card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
}

.card.btn {
  transition: all 0.4s;
}

.card.btn:hover {
  box-shadow: -21px 21px 42px #d9d9d9, 21px -21px 42px #ffffff;
}

.page-card {
  box-shadow: none;
  height: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  min-height: calc(100vh - 70px);
  border: 0px;
}

// Navbar
.navbar {
  .navbar-brand {
    // font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;
  }
}

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

// Modal
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  .modal-content {
    border-radius: 15px;
    // background: #e0e0e0;
    box-shadow: -7px 7px 14px rgba($color: #000000, $alpha: 0.2);
  }
}

.notif-card {
  padding: 1.5rem 3rem 1.5rem 3rem;
  background-color: $secondary;
  box-shadow: -7px 7px 14px rgba($color: #000000, $alpha: 0.1);
  overflow: hidden;
  border-radius: 15px;
}

.action-icon {
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  text-align: center;
  min-width: 33px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 0.3s;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    transform: scale(0.8);
  }

  &:hover::before,
  &.active::before {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1);
  }
}

.editor-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  top: 0px;
  z-index: 100;

  .toolbar-item {
    color: gray;
  }
}

.component-list {
  .component-item {
    text-align: center;
  }
}

.media {
  border: 2px dashed gray;
  border-radius: 10px;
  cursor: pointer;
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .img-asset {
    object-fit: contain;
    width: 100%;
    // height: 300px;
  }
}

.media-icon {
  border-color: $primary;
  color: $primary;
}

.media-add {
  position: absolute;
  right: 18px;
}

.media-image {
  max-width: 100%;
  border-radius: 5px;
  height: 140px;
  object-fit: cover;
  cursor: pointer;
}

.nav-tabs {
  .nav-link {
    cursor: pointer;
  }
}

.progress-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $primary, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.media-image-parent {
  position: relative;
  text-align: center;
}

.media-name {
  line-height: 1.2;
  text-overflow: ellipsis ellipsis;
  max-width: 100%;
}

.main-cover {
  min-height: 100vh;

  .icon-input {
    padding-left: 35px !important;
  }

  &.wallpaper {
    background-image: url('../img/background.png') !important;
    background-size: 300px auto;
  }

  .preloader-container {
    position: relative;
    /* overflow-x: hidden !important; */
    min-height: 5px;
  }

  .main-logo {
    width: 140px;
  }

  .login-card {
    border-radius: 15px;
    overflow: hidden;
  }
}

.intro-card {
  background-color: white !important;
  border: 0px !important;
  border-radius: 15px;
  max-width: 950px;
}
.intro-title {
  color: $primary;
}
.intro-img {
  margin-top: -60px;
  width: 192px;
}
.eye-icon {
  right: 10px;
  cursor: pointer;
  color: grey;
}

.btn-round {
  border-radius: 50px !important;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}

// css loaders

.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 19px;
  height: 19px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($dark, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  z-index: 5000;

  .alert-title {
    font-size: 25px;
    font-weight: bold;
  }

  .content {
    border-radius: 10px;
    width: 500px;
    // min-height: 400px;
    max-width: 100%;
    box-sizing: border-box;
    transform: translateY(50px);
    transition: all 0.5s;
  }
  &.active .content {
    transform: translateY(0px);
  }

  .btn {
    min-width: 100px;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }
}

.blur-alert {
  filter: blur(5px);
}

.notification-container {
  position: fixed;
  bottom: 15px;
  right: 0px;
  max-width: 100%;
  z-index: 10000;
}

.blue-table {
  .search-icon {
    position: absolute;
    right: 12px;
  }

  .empty-holder {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.label {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
}
.custom-input {
  .label {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 12px;
  }
}

.form-control.clean {
  padding: 0px !important;
  border: 0 !important;
}

.resizeable-container {
  display: inline-block;
  // resize: both;
  // overflow: auto;
  // background-color: aliceblue;
  vertical-align: top;

  .container-tab-leaf {
    position: absolute;
    right: 0px;
    margin-top: -35px;
  }
}

.edit-parent {
  position: relative;

  .edit-menu {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    z-index: 10;
    border: 1px solid whitesmoke;
    transition: opacity 0.3s;
  }

  &:hover .edit-menu {
    opacity: 1;
    pointer-events: all;
  }
}

.stats-card-1 {
  background-color: #46b6e3;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.state-richtext {
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.action-list {
  .action-item {
    cursor: pointer;
  }

  .action-img {
    width: 100px;
  }
}

.user-login-card {
  .user-icon {
    width: 100%;
  }
  .user-icon-parent {
    border: 2px solid rgba($primary, 0.5);
    display: inline-block;
    color: $primary;
    // width: auto !important;
    padding: 10px;
    border-radius: 50%;
    position: relative;
    width: 60px;
    height: 60px;
    background-color: rgba($primary, 0.05);
  }
}

.intro-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-card {
  background-color: $primary;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;

  .icon {
    padding: 15px;
    border-radius: 15px;
    // background-color: rgba(white, 0.1);
    color: white;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgba(white, 0.1) 10px,
      rgba(white, 0.1) 20px
    );
  }

  .title {
    font-size: 30px;
    margin-bottom: 2px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  z-index: 5000;
  background-color: white;

  .loader-container {
    max-width: 145px;
  }

  &.floating-loader {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

#nest1 {
  display: block;
  // position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 3px solid transparent;
  border-top-color: $primary;
  border-radius: 50%;
  -webkit-animation: spin7 1.5s ease infinite;
  animation: spin7 1.5s ease infinite;

  &:before {
    content: '';
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: $primary;
    -webkit-animation: spin7 3s linear infinite;
    animation: spin7 3s linear infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: $primary;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 1.5s ease infinite;
  }

  @-webkit-keyframes spin7 {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin7 {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}
.nest-parent {
  margin-top: 50px;
  transform: scale(1.3);
  // background-color: #f75f6d;
}

.calendar-page a {
  text-decoration: none !important;
}

.event-item {
  cursor: pointer;
  background-color: $primary;
  border-radius: 5px;
  color: white;
  padding: 10px;
}

.richtext {
  font-family: 'Exo', sans-serif;
  color: #1c1c1c;
  p {
    padding-top: 1px;
  }

  h5 {
    font-family: 'Libre Baskerville', serif;
  }
  h4 {
    font-family: 'Montserrat', serif;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}

.sticky-card {
  position: sticky;
  top: 15px;
}

.sticky-component-btn {
  position: sticky;
  bottom: 10px;
}

.featured-item {
  width: 350px;
  max-width: 31%;

  .img-asset {
    width: 100%;
    border-radius: 5px;
    height: 180px;
    object-fit: cover;
  }
}

.featured-table {
  min-height: 400px;

  .blue-table {
    min-height: 100%;
  }
}

.terminal {
  scroll-behavior: smooth;
  overflow-y: hidden;
  position: relative;
  // max-height: 0px;
  // background-color: #212121;
  color: white;
  // display: none;

  &.active {
    // display: inherit;
    // max-height: 400px;
  }
}

.media-modal {
  .modal-dialog {
    max-width: 730px !important;
  }
}

.screenshots {
  img {
    // max-width: 100%;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .output-text {
    white-space: pre-wrap;
  }
}

.screenshot-modal {
  // width: 100%;
  // height: 100vh !important;
  // position: fixed;
  // top: 0px;
  // z-index: 1000;
  // pointer-events: none;
  // transition: all 0.3s;
  // background-color: rgba($color: #000000, $alpha: 0.2);
  // opacity: 0;

  .modal-dialog {
    max-width: 1200px;
  }

  .screen-modal {
    width: 1100px;
    max-width: 95vw;
    // max-height: 95vh;
    // min-height: 800px;
    overflow-y: auto;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .screenshot-container {
    width: 300px;
    height: 450px;
  }
}

.clickable-badge {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }

  &:active {
    background-color: $secondary !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.table-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.placeholder {
  border: 1px solid gray;
  padding: 7px 10px;
  border-radius: 3px;
  color: gray;
  min-width: 200px;
  text-align: left;
  font-size: 0.9em;
}

.placeholderWrap {
  display: flex;
}

.placeholderWrap .placeholder:last-child {
  margin-left: 10px;
}

.blue-table {
  .loader {
    z-index: 1;
  }
}

// #__range-picker-container {
// }

.calendar.visible {
  z-index: 5000 !important;
}

.table-container {
  overflow-x: auto;
  td {
    white-space: nowrap;
  }
}

.member-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: $primary;
  margin: 0 auto;
}

.assign-lead-ownership-modal-list {
  min-height: 300px;
  max-width: 500px;
  overflow-y: auto;
}
